<template>
  <div id="mini">
    <mini-converter-online />

    <hr/>
    
    <v-container>
      Mini Converter change log can be found <a href="https://www.patreon.com/MrJoshuaT?filters[tag]=Mini%20Converter" target="_blank">here</a>
    </v-container>
    
    <hr/>

    <mini-converter />

    <hr/>
    
    <v-container>
      <b>
        All images produced by the Mini Converter online or Discord bot are free to use anywhere you wish.
        There is no copyright at all on the images.
        Base helmet and weapon images are taken from Minecraft under fair use <a href="https://account.mojang.com/documents/commercial_guidelines">guidelines</a>.
      </b> 🙂
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'MiniGenerator',

    components: {
      MiniConverter: () => import('@/components/home/MiniConverterDiscordBot'),
      MiniConverterOnline: () => import('@/components/home/MiniConverterOnline')
    },
  }
</script>
