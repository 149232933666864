<template>
  <div id="home">
    <about />
  </div>
</template>

<script>
  export default {
    name: 'Home',

    components: {
      About: () => import('@/components/home/About'),
    },
  }
</script>
