import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import MiniGenerator from '../views/MiniGenerator.vue'
import TOS from '../views/TOS.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import About from '../views/About.vue'

Vue.use(Router)

var router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   component: About,
    // },
    {
      path: '/mini',
      name: 'mini',
      component: MiniGenerator,
    },
    {
      path: '/tos',
      name: 'tos',
      component: TOS,
    },
    {
      path: '/pp',
      name: 'pp',
      component: PrivacyPolicy,
    },
  ],
})

export default router;